<template>
	<div
		class="position-absolute align-content-center"
		:style="positionStyle">
		<div
			:id="`popover-pvb-${value}`"
			class="text-center progress-image"
			:class="statusClass"
			:style="circleStyle">
			{{ value }}
		</div>
		<b-popover
			v-if="status !== 'incomplete' && hoverHtml"
			:target="`popover-pvb-${value}`"
			triggers="hover"
			custom-class="drb-pbv-popover"
			boundary-padding="0"
			placement="top">
			<div
				class="pr-3"
				v-html="hoverHtml" />
		</b-popover>
	</div>
</template>
<script>
export default {
	name: 'Target',
	props: {
		value: {
			type: Number,
			default: 0,
		},
		status: {
			type: String,
			default: 'incomplete',
		},
		left: {
			type: [Number, String],
			default: '',
		},
		right: {
			type: [Number, String],
			default: '',
		},
		hoverHtml: {
			type: String,
			default: '',
		},
	},
	computed: {
		positionStyle() {
			return {
				left: this.left,
				right: this.right,
			};
		},
		circleStyle() {
			return {
				border: this.status === 'incomplete' ? '2px solid' : 'none',
			};
		},
		statusClass() {
			return {
				'bg-light-green': this.status === 'completed',
				'bg-primary': this.status === 'reached',
				'bg-light': this.status === 'incomplete',
				'border-primary': this.status === 'incomplete',
				'text-primary': this.status === 'incomplete',
				pointer: this.status !== 'incomplete',
			};
		},
	},
};
</script>
<style scoped>
.progress-image {
	width: 33px;
	height: 33px;
	border-radius: 100%;
	text-align: center;
	align-content: center;
	font-size: small;
}
</style>
