<template>
	<div>
		<b-card>
			<!-- Promo title -->
			<div class="text-center font-weight-bolder">
				{{ translate('free_products_weeks_widget_title') }}
			</div>
			<!-- Weeks progress -->
			<div class="text-center mt-2">
				{{ translate('fpw_order_activation_months') }}
				<strong>
					{{ totalBv }}
				</strong>
				<template v-if="this.promoInfo.show_weeks">
					<br>
					({{ orderActivationWeeks }} {{ translate('weeks') }})
				</template>
			</div>
			<!-- Progress Bar -->
			<b-progress
				:max="maxValue"
				:value="totalBv"
				animated
				striped
				:variant="totalBv >= maxValue ? 'light-green' : 'primary'"
				height="1em"
				class="mt-3">
				<b-progress-bar
					:value="totalBv">
					<target
						v-for="(milestone, value) in promoInfo.milestones"
						:key="value"
						:value="Number(value)"
						:status="status(value)"
						:hover-html="milestoneReachedText(value)"
						:style="milestonesPositions[value]" />
				</b-progress-bar>
			</b-progress>
			<!-- Promo info -->
			<div
				v-if="totalBv < maxValue"
				class="text-center mt-3"
				v-html="nextMilestoneText" />
			<div
				v-else
				class="text-center mt-3"
				v-html="translate(`fpw_max_prize${translationSuffix}`)" />
		</b-card>
	</div>
</template>
<script>

import { Promotions } from '@/translations';
import Target from '@/components/FreeProductsWeekWidget/target';

export default {
	name: 'FreeProductsWeekWidget',
	components: { Target },
	messages: [Promotions],
	props: {
		totalBv: {
			type: Number,
			default: 0,
		},
		orderActivationWeeks: {
			type: [String, Number],
			default: 0,
		},
		promoInfo: {
			type: Object,
			default: () => ({
				max_bvs: 0,
				show_weeks: false,
				milestones: [],
			}),
		},
	},
	data() {
		return {
			milestonesPositions: {
				144: {
					left: '45%',
				},
				216: {
					right: '25%',
				},
				288: {
					right: '15px',
				},
			},
		};
	},
	computed: {
		maxValue() {
			return this.promoInfo.max_bvs || 0;
		},
		nextMilestoneIndex() {
			return Object.keys(this.promoInfo.milestones).find((milestone) => milestone > this.totalBv) || 0;
		},
		nextMilestoneText() {
			const next = this.promoInfo.milestones[this.nextMilestoneIndex];
			if (next) {
				const nextProducts = Object.values(next.prizes).map((product) => `${product.qty} ${product.name}`);
				if (Object.keys(next.earned).length) {
					const earned = Object.values(next.earned).map((product) => `${product.qty} ${product.name}`);

					return this.translate(`fpw_next_milestone${this.translationSuffix}`, {
						earned_products: earned.join(', '),
						weeks: next.weeks,
						products: nextProducts.join(', '),
						bv: next.bvs,
					});
				}

				return this.translate(`fpw_first_milestone${this.translationSuffix}`, { weeks: next.weeks, products: nextProducts, bv: next.bvs });
			}
			return this.translate(`fpw_max_prize${this.translationSuffix}`);
		},
		translationSuffix() {
			return this.promoInfo.show_weeks ? '' : '_no_weeks';
		},
	},
	methods: {
		status(value) {
			if (this.totalBv >= this.maxValue) {
				return 'completed';
			}
			if (value <= this.totalBv) {
				return 'reached';
			}
			return 'incomplete';
		},
		milestoneReachedText(value) {
			const message = this.translate(`fpw_reached_milestone_${value}_description${this.translationSuffix}`);
			return `<div class="text-center">${message}</div>`;
		},
	},
};
</script>
